import React from 'react';
import {
	Switch,
	Route
} from 'react-router-dom';

const Products = React.lazy(() => import('./views/Products'))
const Sales = React.lazy(() => import('./views/Sales'))
const Orders = React.lazy(() => import('./views/Orders'))
const Customers = React.lazy(() => import('./views/Customers'))
const OrderDelivery = React.lazy(() => import('./views/OrderDelivery'))
const SalesReport = React.lazy(() => import('./views/SalesReport'))

const Routes = () => {
	return (
		<Switch>
			<Route exact path="/">
				<Home />
			</Route>
			<Route path="/sales">
				<Sales />
			</Route>
			<Route path="/products">
				<Products />
			</Route>
			<Route path="/orders">
				<Orders />
			</Route>
			<Route path="/customers">
				<Customers />
			</Route>
			<Route path="/order-delivery">
				<OrderDelivery />
			</Route>
			<Route path="/sales-report">
				<SalesReport />
			</Route>
		</Switch>
	)
}

function Home() {
	return (
		<div>
			<h2>Home</h2>
		</div>
	);
}

export default Routes;
