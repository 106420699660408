import client from './api-client'
import decode from 'jwt-decode';

const localStorageKey = '__token__'

function handleUserResponse(res) {
    // console.log(res.data.token)
    window.localStorage.setItem(localStorageKey, res.data.token)
}

function getUser() {
    const token = getToken()
    if (!token) {
        return Promise.resolve(null)
    }
    return client.get('me').catch(error => {
        logout()
        return Promise.reject(error)
    })
}

function login({ email, password }) {
    return client.post('login', { email, password })
}

function loggedIn() {
    const token = getToken()
    return !!token && !isTokenExpired(token)
}

function isTokenExpired(token) {
    try {
        const decoded = decode(token);
        if (decoded.exp < Date.now() / 1000) {
            return true;
        } else return false;
    }
    catch (err) {
        return false;
    }
}

function register({ username, password }) {
    return client.post('register', { username, password }).then(
        handleUserResponse,
    ).catch(err => { console.log(err) })
}

function logout() {
    window.localStorage.removeItem(localStorageKey)
    return Promise.resolve()
}

function getToken() {
    return window.localStorage.getItem(localStorageKey)
}

export { login, loggedIn, isTokenExpired, register, logout, getToken, getUser }
