import React from 'react'
import { useAuth } from './../context/auth-context'
import {
	NavLink
} from 'react-router-dom';
import {
	Navbar,
	Nav
} from 'react-bootstrap';

const Header = () => {
	const { logout } = useAuth()
	
	return (
		<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="app-header">
			<NavLink to="/" className="navbar-brand">Washup <small>anderkilla</small></NavLink>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="mr-auto">
					<NavLink to="/sales" className="nav-link">Sales</NavLink>
					<NavLink to="/products" className="nav-link">Products</NavLink>
					<NavLink to="/orders" className="nav-link">Orders</NavLink>
					<NavLink to="/order-delivery" className="nav-link">Order delivery</NavLink>
					<NavLink to="/sales-report" className="nav-link">Sales Report</NavLink>
					<NavLink to="/customers" className="nav-link">Customers</NavLink>
				</Nav>
				<Nav>
					<button type="button" className="btn btn-secondary btn-sm" onClick={logout}>Logout</button>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}

export default Header