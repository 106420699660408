import React from 'react'
import { useAsync } from 'react-async'
import { bootstrapAppData } from '../utils/bootstrap'
import * as authClient from '../utils/auth-client'
// import { toast } from 'react-toastify'

const AuthContext = React.createContext()

function AuthProvider(props) {
    const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false)
    const {
        data = { user: null },
        error,
        isRejected,
        isPending,
        isSettled,
        reload,
    } = useAsync({
        promiseFn: bootstrapAppData,
    })

    React.useLayoutEffect(() => {
        if (isSettled) {
            setFirstAttemptFinished(true)
        }
    }, [isSettled])

    if (!firstAttemptFinished) {
        if (isPending) {
            return "Loading"
        }
        if (isRejected) {
            return (
                <div>
                    <p>Uh oh... There's a problem. Try refreshing the app.</p>
                    <pre>{error.message}</pre>
                </div>
            )
        }
    }

	const login = form => authClient.login(form).then((res) => {
        // console.log(res.data)
		window.location.reload()
	}).catch(error => {
        console.log(error.response)
	})
	
    const register = form => authClient.register(form).then(() => {
		window.location.reload()
	})
    const logout = () => authClient.logout().then(reload)
    const loggedIn = () => authClient.loggedIn()

    return (
        <AuthContext.Provider value={{ data, login, loggedIn, logout, register }} {...props} />
    )
}

function useAuth() {
    const context = React.useContext(AuthContext)
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`)
    }
    return context
}

export { AuthProvider, useAuth }
