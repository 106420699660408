import React, { useState } from 'react'
import { login } from './utils/auth-client'
import { useForm } from "react-hook-form"
import { ToastContainer } from 'react-toastify'

const localStorageKey = '__token__'

const LoginForm = () => {
	const { register, handleSubmit, errors, setError } = useForm()

	const [isLoading, setIsLoading] = useState(false)

	const onSubmit = data => {
		setIsLoading(true)
		login(data).then(res => {
			setIsLoading(true)
			window.localStorage.setItem(localStorageKey, res.data.token)
			window.location.reload()
		})
		.catch(err => {
			setIsLoading(false)
			if (err.response && err.response.status === 404)
				setError('email', 'notfound', 'User not found with this email.')
			if (err.response && err.response.status === 422) {
				setError('password', 'invalid', 'Invalid password.')
			}
		})
	}

	return (
		<div className="section is-fullheight">
			<ToastContainer />
			<div className="container">
				<div className="d-md-flex justify-content-center align-items-center">
					<div className="card login-panel" style={{ maxWidth: '500px', minWidth: '400px', marginTop: '50px' }}>
						<div className="card-header">
						Washup Andarkilla panel
						</div>
						<div className="card-body">
							<form
								onSubmit={handleSubmit(onSubmit)}
							>
								<div className="form-group">
									<label className="label">Email</label>
									<div className="control">
										<input
											className="form-control"
											type="email"
											name="email"
											ref={register({ required: true })}
										/>
										{
											errors.email &&
											errors.email.type === 'required' &&
											<small className="form-text text-danger">
												Email is required.
											</small>
										}
										{
											errors.email &&
											errors.email.type === 'notfound' &&
											<small className="form-text text-danger">
												{errors.email.message}
											</small>
										}
									</div>
								</div>
								<div className="form-group">
									<label className="label">Password</label>
									<div className="control">
										<input
											className="form-control"
											type="password"
											name="password"
											ref={register({ required: true })}
										/>
										{
											errors.password &&
											errors.password.type === 'required' &&
											<small className="form-text text-danger">
												Password is required.
											</small>
										}
										{
											errors.password &&
											errors.password.type === 'invalid' &&
											<small className="form-text text-danger">
												{errors.password.message}
											</small>
										}
									</div>
								</div>
								{/* <button type="submit" className="btn btn-success">Login</button> */}
								<button className="btn btn-success" type="submit" disabled={isLoading}>
									{isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
									Login
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};



function UnauthenticatedApp() {
	return (
		<LoginForm />
	)
}

export default UnauthenticatedApp
