import axios from 'axios';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('__token__');

var api_url = 'http://localhost:8001/api'

if (process.env.NODE_ENV === 'production') {
  api_url = 'http://wj-api.echobazar.xyz/api'
}

export default axios.create({
  baseURL: api_url,
  headers: {'Access-Control-Allow-Origin': '*'}
});